<template>
  <div id="profile-page">
    <section class="profile-section">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-3 col-sm-4">
            <div class="menu-profile-mobile mb-3">
              <button class="btn btn-mobile" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
                aria-expanded="false" aria-controls="collapseExample">
                <span class="icon-ico-user"></span> General
              </button>
              <div class="collapse" id="collapseExample">
                <div class="card card-body">
                  <ul class="nav-mpd">
                    <li>
                      <a href="profile.html" class="link-mpd"><span class="icon-ico-user"></span> General</a>
                    </li>
                    <li>
                      <a href="notification.html" class="link-mpd"><span class="icon-ico-notif"></span> Notification</a>
                    </li>
                    <li>
                      <a href="#" class="link-mpd" @click="logout"><span class="icon-ico-logout"></span> Logout</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="menu-profile-desktop">
              <div class="mpd-top">
                <ul class="nav-mpd">
                  <li>
                    <a href="#" class="link-mpd" :class="{ active: tab == 'general' }" @click="tab = 'general'">
                      <span class="icon-ico-user"></span> General
                    </a>
                  </li>
                  <li>
                    <a href="#" class="link-mpd" :class="{ active: tab == 'notification' }" @click="tab = 'notification'">
                      <span class="icon-ico-notif"></span> Notification
                    </a>
                  </li>
                </ul>
              </div>
              <div class="mpd-bottom">
                <a href="#" class="link-mpd" @click="logout"><span class="icon-ico-logout"></span> Logout</a>
              </div>
            </div>
          </div>

          <template v-if="tab == 'general'">
            <div class="col-lg-8 col-sm-8">
              <div class="row mb24 align-items-center">
                <div class="col-sm-2">
                  <div class="bx-img-upload">
                    <template v-if="form.upload.fileUrl">
                      <img :src="form.upload.fileUrl" class="img-fluid">
                    </template>
                    <template v-else>
                      <img v-if="$store.state.avatar === null" src="/img/default-profile-picture.png" class="img-fluid">
                      <img v-else :src="$store.state.avatar" class="img-fluid">
                    </template>
                  </div>
                </div>
                <div class="col-sm-10">
                  <div class="d-flex mb-3">
                    <div class="upload-btn-wrapper mr15">
                      <button class="btn btn-icon" type="button">Upload</button>
                      <input type="file" name="myfile" ref="myfile" id="myfile" @change="preview" />
                    </div>
                    <button class="btn btn-reset" type="button" @click="clearAvatar">Reset</button>
                  </div>

                  <div class="img-upload-info">*Allowed JPG, GIF or PNG. Max size of 800kB</div>
                </div>
              </div>

              <h2 class="detail-title mb24">Personal Info</h2>
              <div class="row">
                <div class="col-sm-6 col-lg-6">
                  <div class="create-form">
                    <label class="label-form">Full Name</label>
                    <input type="text" class="form-control" :value="$store.state.user.name" placeholder="Full Name" readonly>
                  </div>
                </div>
                <div class="col-sm-6 col-lg-6">
                  <div class="create-form">
                    <label class="label-form">NIP</label>
                    <input type="text" class="form-control" :value="$store.state.user.nim" placeholder="NIP" readonly>
                  </div>
                </div>
                <div class="col-sm-6 col-lg-6">
                  <div class="create-form">
                    <label class="label-form">Email</label>
                    <input type="email" class="form-control" :value="$store.state.user.email" placeholder="Email" readonly>
                  </div>
                </div>
                <div class="col-sm-6 col-lg-6">
                  <div class="create-form">
                    <label class="label-form">Position</label>
                    <input type="text" class="form-control" :value="$store.state.user.eduPersonAffiliation" placeholder="Position" readonly>
                  </div>
                </div>
                <div class="col-sm-6 col-lg-6" v-if="$store.getters.userType == 'master'">
                  <div class="create-form">
                    <label class="label-form">Alias</label>
                    <input type="text" class="form-control" v-model="form.alias" placeholder="Alias">
                  </div>
                </div>
              </div>

              <div class="for-btn-save">
                <div class="btn-save-box">
                  <button class="btn btn-orange w-100" type="button" @click="saveAvatar">Save Changes</button>
                </div>
                <div class="btn-delete-box">
                  <button class="btn btn-delete w-100" type="button" @click="clearAvatar">Reset</button>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="col-lg-8 col-sm-8">
              <h2 class="detail-title mb24">Activity</h2>
              <template v-for="(notif, index) in notification">
                <div class="form-check form-switch mb-4" :key="index">
                  <input class="form-check-input switch" type="checkbox" :id="`switch${index}`" v-model="notif.notification_status">
                  <label class="form-check-label label-switch" for="switch1">
                    {{ notif.notification_type | titleCase }}
                  </label>
                </div>
              </template>
              <div class="for-btn-save">
                <div class="btn-save-box">
                  <button class="btn btn-orange w-100" type="button" @click="saveSetting">Save Changes</button>
                </div>
                <div class="btn-delete-box">
                  <button class="btn btn-delete w-100" type="button" @click="resetNotif">Reset</button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import cookie from "js-cookie";
import Swal from "sweetalert2";

export default {
  name: "Profile",
  data() {
    return {
      tab: "general",
      form: {
        upload: { 
          isEmpty: true,
          filename: null,
          file: null,
          fileUrl: null
        },
        alias: this.$store.state.user.alias
      },
      notification: []
    }
  },
  mounted() {
    this.getNotificationSettings()
  },
  methods: {
    logout() {
      // clear vuex
      this.$store.commit("SET_USERDATA", null);
      // clear cookie
      cookie.remove("userdata");
      cookie.remove("avatar");
      // redirect after login
      window.location.href = '/';
    },
    preview(event) {
      if(event.target.files[0] !== undefined){
        let extension = event.target.files[0].name.split(".").pop()
        let allowedExtention = ['jpg','jpeg','png','gif']
        if(allowedExtention.includes(extension.toLowerCase())){
          this.form.upload.isEmpty = false
          this.form.upload.filename = event.target.files[0].name
          this.form.upload.file = event.target.files[0]
          this.form.upload.fileUrl = URL.createObjectURL(event.target.files[0]);
        }
      }
    },
    clearAvatar() {
      this.form.upload.isEmpty = true
      this.form.upload.filename = null
      this.form.upload.file = null
      this.form.upload.fileUrl = null
      document.getElementById('myfile').value = null
    },
    async saveAvatar() {
      if (this.$store.getters.userType == 'master') {
        await this.changeAlias()
      }

      let param = new FormData();
      param.append('file', this.form.upload.file);
      param.append('folder', `avatar/${this.$store.state.user.id}`);
      param.append('quality', '80');

      await this.$axios.post('/set_avatar', param)
        .then(async (response) => {
          console.log(response.data)
          Swal.fire("Success", response.data.message, "success");
          await this.getAvatar()
        })
    },
    async changeAlias() {
      this.$axios.patch('/user/change_alias', { alias: this.form.alias })
        .then(response => {
          console.log(response)
          // get data from cookie
          const user = cookie.getJSON("userdata");
          user.alias = this.form.alias
          console.log(user);
          // save data to cookie
          cookie.set("userdata", user);
          // save data to vuex
          this.$store.commit("SET_USERDATA", user);
          Swal.fire("Success", 'alias successfuly updated', "success");
        })
    },
    getAvatar() {
      this.$axios.get('/avatar')
        .then(response => {
          console.log(response)
          if (response.data.success) {
            this.$store.commit("SET_AVATAR", response.data.data.avatar)
            // save data to cookie
            cookie.set("avatar", { avatar: response.data.data.avatar }, { expires: 1 });
          }
        })
    },
    getNotificationSettings() {
      this.$axios.get('/setting_notification')
        .then(response => {
          console.log(response)
          this.notification = response.data.data
        })
    },
    resetNotif() {
      this.notification = this.notification.map(item => {
        return {
          id: item.id,
          user_id: item.user_id,
          notification_status: false,
          notification_type: item.notification_type,
          created_at: item.created_at,
          updated_at: item.updated_at,
        }
      })
    },
    saveSetting() {
      for (let index = 0; index < this.notification.length; index++) {
        const notif = this.notification[index];
        this.$axios.patch('/setting_notification', {
          notification_type: notif.notification_type,
          notification_status: notif.notification_status
        })
          .then(response => {
            Swal.fire("Success", response.data.message, "success");
          })
      }
    }
  }
}
</script>